import React, { useState, useEffect } from "react";
import ImageUploader from "./ImageUploader";
import Icon  from "./Icon";
//style in App.css




export const UpdateImagePopUp = ({ onClose, initialImageUrl,onImageUpload }) => {
    const [imageUrl, setImageUrl] = useState(initialImageUrl);
    const [isUpdated, setIsUpdated] = useState(false);
    const [newImageUrl, setNewImageUrl] = useState(null);
    const [newImage, setNewImage] = useState(initialImageUrl);
   
  
    const handleUpload = (newImageUrl, newImage) => {
        setNewImageUrl(newImageUrl);
        setNewImage(newImage);
        setIsUpdated(true);
    };
  
    const handleConfirmUpload = () => {
        onImageUpload(newImage)
        onClose();
    };
    // Styles en ligne pour le popup
    const overlayStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    };
  
    const contentStyle = {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      maxWidth: '500px',
      width: '80%',
      textAlign: 'center',
    };
  
    const closeButtonStyle = {
      position: 'absolute',
      top: '10px',
      right: '10px',
      cursor: 'pointer',
      border: 'none',
      background: 'transparent',
      fontSize: '1.5rem',
    };
  
    return (
      <div style={overlayStyle}>
        <div style={contentStyle}>
          
          <h1 className="mb-4" style={{ fontSize: '1.2rem', marginBottom: '1rem', marginTop: '1rem'}}>Ajoute ton fichier ou image</h1>
          <div>
            
              <>
                {!newImageUrl && imageUrl && <img src={imageUrl} alt="Image initiale" style={{ maxHeight : '15rem',maxWidth: '100%', marginBottom: '1rem' }} />}
                  <ImageUploader onImageUpload={handleUpload}/>
              </>
        </div>
          {isUpdated && (
            <div>
                <button onClick={handleConfirmUpload} className="mt-3 btn btn-primary btn-block" type="submit">
                    Confirmer
                </button>
            </div>
          )}
          <button className="mt-3 btn btn-secondary btn-block" onClick={onClose}>Fermer</button>
        </div>
      </div>
    );
  };
  



const MiniFunction = ({ image,text }) => {
    const url=process.env.PUBLIC_URL +'/icons/'+image;
    console.log("url bagde : ",url);
    return (
        <div className='text-center function' >
            <div>
                <div  className='mx-auto function-carre'>
                      <Icon 
                        src={image} 
                        alt="Contenu" 
                        className=' function-image'/>
                
                    </div>
            <p>{text}</p>
            </div>
            
        </div>
    );
};




export default MiniFunction;
