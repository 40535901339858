import React, { useState } from 'react';

import { FieldText,FieldTextArea } from '../common/EditableField';
import AuthServiceInstance from '../../services/auth.service';
import ApplianceServiceInstance from '../../services/appliance.service';

const NewDeviceConfirm = ({ newDevice, getFinalDevice}) => {
  
    const [apDetail] = useState(newDevice);
    const [toModif, setToModif] = useState(false);
   
    console.log('detail device : ',apDetail);

    const [apUpdate, setApUpdate] = useState(null);
    
    const handleMarqueChange = (event) => {
        setApUpdate((prevApUpdate) => ({
            ...prevApUpdate,
            marque: event.target.value
          }));
    };
    const handleModeleChange = (event) => {
        setApUpdate((prevApUpdate) => ({
            ...prevApUpdate,
            modele: event.target.value
          }));
    };
    const handleDescriptionChange = (event) => {
        setApUpdate((prevApUpdate) => ({
            ...prevApUpdate,
            note: event.target.value
          }));
    };
   
    const gotoFin = (device) => {
        getFinalDevice(device);
    };

    const gotoModif = () => {
        setToModif(!toModif);
        setApUpdate(apDetail);
    };

    const onCloseModif = () => {
        setToModif(!toModif);
        setApUpdate(null);
    };

    const updateAppliance = async () => {
        
        const user = AuthServiceInstance.getCurrentUser();
        try {
            const updatedApp = await ApplianceServiceInstance.updateApplianceText(user.id,apUpdate);
            if (updatedApp) {
                gotoFin(updatedApp);
                setToModif(!toModif);
            }
        }catch (error) {
            console.error('Erreur lors de la update :', error);
        } finally {
            
        }
    };



  return (
    <div> 

        {toModif && (
            <div>
              <FieldText
                  label="Marque"
                  value={apUpdate.marque}
                  onChange={handleMarqueChange}
                  required
                />
                 <FieldText
                  label="Modele"
                  value={apUpdate.modele}
                  onChange={handleModeleChange}
                  required
                />
              <FieldTextArea
                  label="Description"
                  value={apUpdate.note}
                  onChange={handleDescriptionChange}
                  required
                />
                <div  style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <div 
                    className="me-2 text-medium"
                    onClick={() => onCloseModif()} // Le clic ici appelle correctement la fonction de fermeture
                    style={{
                      border: '1px solid black',
                      borderRadius: "0.25rem",
                      width: "auto",
                      height: "100%",
                      padding: '0.5rem',
                      cursor: 'pointer' // Indication visuelle que cet élément est cliquable
                    }}>
                    ANNULER
                  </div>
                  <div 
                    className="col text-center text-medium"
                    onClick={updateAppliance}
                    style={{
                      background: "var(--color-green)",
                      borderRadius: "0.25rem",
                      color: "white",
                      width: "auto",
                      height: "100%",
                      padding: "0.5rem",
                      cursor: 'pointer'
                    }}>
                    VALIDER
                  </div>      
                </div>
            </div>
            
        )}   
       
       {!toModif && (    
        <div>
            <div className="mt-4 text-center">   
                <img 
                    src={apDetail.pict} 
                    alt={apDetail.prod_name} 
                    className="img-fluid"
                    style={{ maxWidth: '18.75rem' }}/>
                
                <div className="mt-1 tex-medium">{apDetail.product.prodName}</div>
                
                <div className="mt-1 text-small">{apDetail.name}</div>
                {apDetail.classenergie && ( // Vérifie si apDetail.classenergie existe
                    <div className="mt-1 text-small">Classe énergétique : {apDetail.classenergie}</div>
                )}
                
            </div>
            
            <div className="mt-4" style={{ position: 'relative' }}>
                <div
                    className="d-flex justify-content-between align-items-end text-medium"
                    style={{ paddingRight: '2rem', height: '100%' }}
                    >
                    <div style={{ alignSelf: 'flex-end' }}>
                        Description
                    </div>
                </div>
            </div>

            <div className="mt-2">   
                    <div className="green-border"
                        style={{ fontSize: '0.75rem', fontWeight: '400', padding: '0.5rem' }}
                    >
                        <div className="">Enregistré le {new Date(apDetail.dt_creation).toLocaleDateString()}</div>
                        <div className="">Date d'achat :  {apDetail.dt_achat ? new Date(apDetail.dt_achat).toLocaleDateString() : 'Inconnu'}</div>
                        <div align="justify">{apDetail.note}</div>  
                    </div>  
            </div> 
            <div className='d-flex'>
                <button onClick={gotoModif} className="mt-3 btn btn-secondary btn-block me-2" type="submit">
                    Modifier
                </button>
                <button onClick={gotoFin} className="mt-3 btn btn-primary btn-block" type="submit">
                    Confirmer
                </button>
            </div>
         </div>)}
        
         
    </div>
    );
};

export default NewDeviceConfirm;
