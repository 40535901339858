import React, { useEffect, useState } from "react";
import Simulateur from "../common/C02Data"; 
import { PageTitle } from "../common/Header";

const CarboTool = () => {

    return (
        <div>
            <PageTitle title="CarboTool" />
            <Simulateur className="mt-3 mb-3"></Simulateur>
        </div>
    );
};

export default CarboTool;