import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {

return (
    <div
                
    style={{ 
        margin: "0px",
        padding: "0px",
        height:"163px",
        width: "100%",
        borderRadius: "0px", 
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        backgroundColor: "#F7F8FC",
       
    }}>
    {/* BannerA (gauche) */}
        <img
            src={process.env.PUBLIC_URL + '/icons/BannerA.svg'}
            alt="Banner Left"
            style={{
            position: "absolute",
            left: "0px",
            width: "auto",
            height: "50px",
            }}
        />
        
        {/* BannerB (droite) */}
        <img
            src={process.env.PUBLIC_URL + '/icons/BannerB.svg'}
            alt="Banner Right"
            style={{
            position: "absolute",
            right: "0px",
            width: "auto",
            height: "163px",
            }}
        />
    
 <div className="app-container container-fluid " style={{ 
            position: "relative",
            zIndex: 2, // Placer ce div au-dessus de l'image
            height:"100%",
            width:"100%",
            borderRadius: "0px",
            boxSizing: "border-box",
            borderTop: "1px solid lightgray",   // Add top border
            borderBottom: "1px solid lightgray", // Add bottom border
            alignContent: "center"
            }}>
            <br/>
            <h1 className="text-banner text-small">
                    <span>Un jardin bien entretenu donne de belles fleurs. </span>
                    <span>Planifier les prochains entretien de vos appareils </span> 
            </h1>
            <div 
                className="col-4 col-md-2 col-lg-2  mt-2 text-center"
                style={{
                    background:"var(--color-green)",
                    borderRadius:"0.25rem",
                    color: "white",  
                    textDecoration: "none",
                    height: "25px",
                    marginLeft: "20px"
                    }}>
            <a 
                        style={{
                           alignContent:"middle",
                            color: "white",              
                            fontSize:"12px",
                            fontWeight:"500",
                            textDecoration: "none",
                            }} href="/#/calendar">EN SAVOIR PLUS &rarr; {/* Flèche droite simple */}</a>
    </div>      
          
    </div>
    </div >
    );
};

export default Banner;