import React, { useState}  from "react";

import ImageUploader from './ImageUploader'; // Suppose que vous avez un composant ImageUploader
import ApplianceService from '../../services/appliance.service'; // Le service pour upload
import UniverService from '../../services/univer.service'; // Importez votre fonction getUniver si elle est dans un autre fichier
import { FieldText, FieldTextArea } from "./EditableField";
import UploadServiceInstance from "../../services/upload.service";

const UniverCRUDForm = ({ show, onClose, univer, action, isModal }) => {

  const [univerName, setUniverName] = useState(univer ? univer.name : '');
  const [pict, setPict] = useState(univer ? univer.image: '');
  const [filePict, setFilePict] = useState(null);
  const [newImage, setNewImage] = useState(false);
  const [univerDescription, setUniverDescription] = useState(univer ? univer.description : '');
 
  const initialUniverName = univer ? univer.name : '';
  const initialUniverDescription = univer ? univer.description : '';
  
  if (!show) {
    return null; // Si `show` est faux, on ne rend rien.
  }


  // Styles conditionnels pour le rendu modal ou page
  const containerStyle = isModal
    ? {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        overflow: 'hidden',
      }
    : {
        width: '100%',
        margin: '0 auto',
      };

      // Styles conditionnels pour le rendu modal ou page
  const subcontainerStyle = isModal
  ? { backgroundColor: 'white', 
    padding: '20px', 
    borderRadius: '10px', 
    width: '80%',  
    maxHeight: '80%', // Limite la hauteur du modal à 80% de la fenêtre
    overflowY: 'auto', // Active le défilement vertical à l'intérieur du modal
    textAlign: 'center'}
  : {
      width: '100%',
      padding: '0',
    };



  // Validation de l'entrée pour le nom de l'univers
  const handleNameChange = (e) => {
    const value = e.target.value;
    // Valider l'entrée pour prévenir des injections de scripts malveillants (XSS)
    const sanitizedValue = value.replace(/[<>]/g, ''); // Simple exemple pour retirer < et >
     // Valider l'entrée pour prévenir des injections de scripts malveillants (XSS)
    setUniverName(sanitizedValue);
  };

  // Validation de l'entrée pour la description de l'univers
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    // Sanitize pour éviter XSS et maintenir le format avec la date de création
    const sanitizedValue = value.replace(/[<>]/g, ''); 
    setUniverDescription(`${sanitizedValue}`);
  };


  // Utilisez useCallback pour mémoriser handleImageUpload et éviter qu'il soit recréé à chaque re-render
  const handleImageUpload = (uploadedImage, fileupload) => {
    console.log('uploaded image jjjjjjjjjjpopdsdqsdkq');
    setPict(uploadedImage);  // Mettez à jour url de l'image téléchargée
    setFilePict(fileupload);//Mettez à jour le fichier
    console.log('file updated ********', filePict)
    setNewImage(true);       // Met à jour l'état indiquant que l'image a été remplacée
  };  
  
   // Fonction pour gérer la mise à jour du nom de l'univers
   const createUniver = async () => {


    const today = new Date().toLocaleDateString();
  
    const refUniverId = univer.univer_id !== -1 ? univer.univer_id : univer.refuniver_id;
    const name = univerName;
    const description = action === 'CREATE' 
  ? `Date de création : ${today}\n${univerDescription}` 
  : `Date de mise à jour : ${today}\n${univerDescription}`;

    const filetouse=await UploadServiceInstance.initializeFile(filePict,pict,univerName);

    
    console.log('file to upload ready ', filetouse);

    const updatedFields = {};
    if (action === 'UPDATE') {
      if (univerName !== initialUniverName) {
        updatedFields.name = univerName;
      }

      if (univerDescription !== initialUniverDescription) {
        updatedFields.description = description;
      }

      if (filePict) {
        updatedFields.file = filePict;
        console.log('new image detected ',updatedFields.file)
      }
    }

    const fetchUnivers = async () => {
      try {
          console.log('calling create univer! ');
          console.log('calling refUniverId! ',refUniverId);
          console.log('calling name! ',name);
          console.log('calling description! ',description);

          const response = action === 'CREATE' 
            ? await UniverService.creatUniver(refUniverId, filetouse, name, description)
            : await UniverService.updateUniver(refUniverId,  updatedFields.file, updatedFields.name, updatedFields.description);
          console.log('univer créé ou maj! ', response);
          onClose(response);
      } catch (error) {
          console.error("Erreur lors de la récupération des univers:", error);
      }
    };

    fetchUnivers();  // Appel de la fonction asynchrone pour récupérer les univers
  };

  return (
            <div style={containerStyle}>
              <div style={subcontainerStyle}>
                <div style={{ fontSize: '1.25rem', fontWeight: '500', textAlign: 'center' }}>
                    {action === "CREATE" 
                    ? `Crée ${univer.name}` 
                    : `Personnalise  ${univer.name}`}
                </div>
                
              {/* Formulaire pour le nom de l'univers */}
              <FieldText
                  label="Nom"
                  value={univerName}
                  onChange={handleNameChange}
                  required
                />
                
              <FieldTextArea
                  label="Description"
                  value={univerDescription}
                  onChange={handleDescriptionChange}
                  required
                />
            
              <div style={{ marginTop: '15px',textAlign: 'start'}}>
                  {!newImage ? (
                    <div>
                      <div className="mb-2 text-medium" >Image actuelle</div>
                      <img className= "d-block mx-auto" src={pict}  
                      alt="image" style={{ maxWidth: '100%', maxHeight:'120px'}} />
                      <div className="mt-2 text-medium">Remplacer avec ma photo</div>
                    </div>
                  ) : (
                    <div>
                      <div className="mb-2 text-medium">Ma photo de {univer.name}</div>
                    </div>
                  )}
                  <ImageUploader uploadImageFunction={ApplianceService.uploadFacture} onImageUpload={handleImageUpload}/>
                </div>


                <div  style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <div 
                    className="me-2 text-medium"
                    onClick={() => onClose(null)} // Le clic ici appelle correctement la fonction de fermeture
                    style={{
                      border: '1px solid black',
                      borderRadius: "0.25rem",
                      width: "auto",
                      height: "100%",
                      padding: '0.5rem',
                      cursor: 'pointer' // Indication visuelle que cet élément est cliquable
                    }}>
                    ANNULER
                  </div>
                  <div 
                    className="col text-center text-medium"
                    onClick={createUniver}
                    style={{
                      background: "var(--color-green)",
                      borderRadius: "0.25rem",
                      color: "white",
                      width: "auto",
                      height: "100%",
                      padding: "0.5rem",
                      cursor: 'pointer'
                    }}>
                    VALIDER
                  </div>      
                </div>
              </div>
            </div>
  );
};

export default UniverCRUDForm;
