import React, { useEffect, useState } from "react";
import ADEMEActorMap from "../common/ADEMEActorMap";
import { PageTitle } from "../common/Header";

const CarboMap = () => {

    return (
        <div>
            <PageTitle title="Longue vie aux objets ! La nouvelle carte publiée par l’Ademe" />
            <ADEMEActorMap className="mt-3 mb-3" typeaction="all"></ADEMEActorMap>
        </div>
    );
};

export default CarboMap;