import React, { useState } from 'react';
import AuthService from "../../services/auth.service";
import SearchService from "../../services/search.service";
import UploadServiceInstance from "../../services/upload.service";

import NewDeviceForm from './NewDeviceForm';
import DropDownAndSearch from '../common/DropDownAndSearch';
import RadioButtonChoice from '../common/RadioButtonChoice';
import ImageUploader from '../common/ImageUploader';
import WaitingModal from '../common/WaitingModal';

const NewDeviceDetection = ({ refProductList, univerChoice, getAppliance }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [pict, setPict] = useState(null);
  const [pictURL, setPictURL] = useState(null);
  const [addMethod, setAddMethod] = useState("");
  const [precision, setPrecision] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(''); // État pour le message d'erreur


  const addMethods = [
    { id: 'manual', value: 'manual', label: 'Entrée manuelle' },
    { id: 'imageDetection', value: 'imageDetection', label: 'Reconnaissance d\'image' },
  ];

  const handleSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleImageUpload = (uploadedUrl, uploadedImage) => {
    setPict(uploadedImage);
    setPictURL(uploadedUrl);
  };

  const handleSearchClick = async () => {
    setIsLoading(true);
    console.log('*****************before search  :');
    try {
      const user = AuthService.getCurrentUser();
      const uploadResult = await UploadServiceInstance.uploadTempFile(pict);
      const url = uploadResult ? uploadResult.fileUrl : "";
      const textToSearch = precision ? `${precision}` : 'machine';
      const univerId = univerChoice.id ? univerChoice.id : univerChoice.univer_id;
      
      // Appel du service de recherche pour obtenir `appliance`
      const appliance = await SearchService.searchAndSaveByImage(url, textToSearch, user.id, univerId, 15);
      console.log('*****************appliance  :', appliance);
      // Appel de `createAppliance` pour envoyer `appliance` à `AddDevicePage` si appliance est crée avec succès
      if (appliance) getAppliance(appliance);
      else {
        setErrorMessage("Veuillez apporter plus de précision à la recherche."); // Définir le message d'erreur
      }
    } catch (error) {
      console.error('Erreur lors de la recherche :', error);
      setErrorMessage("Une erreur est survenue lors de la recherche."); // Message d'erreur générique en cas de problème
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      

      {(
        <div>
          <p className="mt-3 text-small">Ajouter l'image de ton appareil</p>
          <ImageUploader onImageUpload={handleImageUpload} />
        </div>
      )}

      
      {pict && (
        <RadioButtonChoice
          legend={`Choisissez comment l’intégrer dans ${univerChoice.name}`}
          options={addMethods}
          selectedValue={addMethod}
          onChange={setAddMethod}
        />
      )}

      {addMethod === 'manual' && <NewDeviceForm />}

      {addMethod === 'imageDetection' && (
        <div>
         <p className="mt-3 text-small" style={{ fontStyle: 'italic' }}>Ajoutez une précision pour faciliter la recherche (facultatif)</p>
         <input
            type="text"
            maxLength="50"
            className="form-control text-small mb-3"
            onChange={(e) => setPrecision(e.target.value)}
          />  


          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

          <button onClick={handleSearchClick} className="mt-3 btn btn-primary btn-block" type="submit">
            Rechercher
          </button>
        </div>
      )}

      {isLoading && <WaitingModal />}
    </div>
  );
};

export default NewDeviceDetection;
