import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import AuthService from '../services/auth.service'; 
import UserService from '../services/user.service'; 
import AppBadge from '../components/common/AppBadge'; 
import UniverServiceInstance from '../services/univer.service';
import { Link,useNavigate } from 'react-router-dom';
import ADEMEActorMap from './common/ADEMEActorMap';
import Simulateur from './common/C02Data';
import { useLocation } from 'react-router-dom';
import { withRouter } from '../common/with-router';
import { calculateTotalCarbonImpact } from './common/C02Data'
import ApplianceServiceInstance from '../services/appliance.service'


const Home = () => {


  const location = useLocation();
  const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
 
  const navigate = useNavigate();
  
  const [currentUser, setCurrentUser] = useState(undefined);
  const [badges, setBadges] = useState(true);
  const [univers, setUnivers] = useState(null);
  const [universToShow, setUniversToShow] = useState(null);
  const [impactTotal, setImpactTotal] = useState(0);
  const [productTotal, setProductTotal] = useState(0);
  
  
  const [isLoading, setIsLoading] = useState(true);

  /**Contruction chart */
  const chartType = 'doughnut';
  const labels = ['PEM', 'GEM'];
  const data = [10, 20];
  // Utiliser les couleurs personnalisées
  const backgroundColors = ['#4FB573', '#E2F3E8']; // Couleurs spécifiées


  // Construire les parties de l'URL
  const quickChartBaseUrl = "https://quickchart.io/chart?v=3&c=";
  const chartConfig = {
    type: chartType,
    data: {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: backgroundColors, // Spécifier les couleurs ici
        borderRadius: 20, 
        borderWidth: 0, // Ajouter un espace entre les segments (ajustez cette valeur selon votre besoin)
        borderColor: '#FFFFFF', // Utilisez une couleur blanche pour l'espace entre les segments (ou une autre couleur si vous préférez)
        borderJoinStyle: 'round', // Arrondir les jointures des segments
        spacing: 5, // Espace entre les segments
      }]
    },
    options: {
      cutout: '80%', // Amincir le doughnut (80% d'ouverture)
      plugins: {
        datalabels: {
          display: false // Désactiver les labels sur les segments
        },
        legend: {
          display: false // Désactiver la légende
        }
      },
      elements: {
        arc: {
          borderRadius: 20, // Arrondir les coins des segments
        }
      },
    },
  };

  // Encoder l'objet chartConfig en chaîne JSON pour l'URL
  const chartUrl = quickChartBaseUrl + encodeURIComponent(JSON.stringify(chartConfig));
      

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    
    if (user) {
      console.log('Hello! setting currentUser ', user.username);
      setCurrentUser(user);
     
      const fetchService = async () => {
        try {

            UserService.getBadge(user.id).then(
              response => {
                if (response.data) {
                  setBadges(response.data);
                } else {
                  setBadges(false);
                }
                console.log('badges! ', response.data);
              },
              error => {
                console.error('Error', error);
              }
            );

            // Récupérer les univers
            const data = await UniverServiceInstance.getUniver();  // Récupérer les univers
            const universCustom = data.filter(univer => univer.univer_id !== -1);
            const universReference = data.filter(univer => univer.univer_id === -1);
            const sortedData = [...universCustom, ...universReference]; // Concaténer les deux listes
            const firstFourElements = sortedData.slice(0, 4); // Prendre uniquement les 4 premiers éléments
            setUnivers(sortedData); // Mettre à jour l'état avec les univers triés
            setUniversToShow(firstFourElements)
            console.log('univers! ', data);
            setIsLoading(false);

            const response = await ApplianceServiceInstance.getApplianceCountByUser(user.id);
            const impactTotal = calculateTotalCarbonImpact(response);
            setImpactTotal(impactTotal);

            let totalAppliances = 0;
            // Récupérer le nombre total d'appareils
            if (response)  totalAppliances=response?.meta?.totalAppliances || 0;
            setProductTotal(totalAppliances);

        } catch (error) {
            console.error("Erreur lors de la récupération des informarions du user:", error);
        }
      };

      fetchService();  // Appel de la fonction asynchrone pour récupérer les univers
    }
  }, []); // Le tableau de dépendances vide signifie que l'effet s'exécute uniquement au premier rendu



  // Définir la fonction showAllBadges
  const showAllBadges = () => {
    // Logique pour afficher tous les badges
    console.log('Afficher tous les badges');
  };

  // Définir la fonction showAllBadges
  const gotoSecondLife = () => {
    const tosecond=true;
    navigate(`/inventaire`,{ state: { tosecond } });
  };

  
  const gotoSimu = () => {
    navigate(`/carbotool`);
  };

  const gotoMap = () => {
    navigate(`/carbomap`);
  };

  // Définir la fonction showAllBadges
  const gotoAddProduct = () => {
    navigate(`/new-device`);
  };

  // Définir la fonction showAllBadges
  const gotoMaintenanceProduct = () => {
    const tomaintenance=true;
    navigate(`/productlist`,{ state: { tomaintenance } });
  };

  // Définir la fonction showAllBadges
  const gotoRepair = () => {
    const torepair=true;
    navigate(`/productlist`,{ state: { torepair } });
  };

  // Fonction appelée lorsqu'un univers est cliqué
  const gotoUniver = (currentUniver) => {
    if (currentUniver.univer_id !== -1) {
        // Rediriger vers univerdetail et passer l'objet currentUniver dans l'état
        navigate(`/inventaire/univers/${currentUniver.univer_id}/detail`, { state: { univer: currentUniver } });
      } else {
        navigate(`/inventaire/univers`,{ state: { univers: univers } });
      }
  };

  // Définir la fonction showAllBadges
  const gotoUnivers = () => {
    navigate(`/inventaire/univers`,{ state: { univers: univers } });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2
  };

  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }

  //<ADEMEActorMap className="mt-3 mb-3"></ADEMEActorMap>
  return (
        <div className="mt-4">
              <h1 className="mt-3" 
                        style={{ 
                        fontSize:"1.25em",
                        fontWeight: "600",
                        wordWrap: "break-word", // Permet de casser les mots à la fin de la ligne
                        wordBreak: "break-word"
                        }}>Hello {currentUser.firstname} !</h1>
              <span className='text-small'>Qu’est-ce que vous voulez faire aujourd’hui ?</span>
               
              <div className="mt-3" >   
                  <Slider {...settings} className="mt-3">
                    <div className="d-flex flex-column align-items-center green-text text-medium"
                      onClick={() => gotoAddProduct()}> 
                      <div className="card-caroussel light-green-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Heart.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>Ajouter</span>
                    </div>
                    <div  
                     className="d-flex flex-column align-items-center text-medium"
                     onClick={() => gotoMaintenanceProduct()}>
                      <div className="card-caroussel light-gray-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Maintenance.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>Entretenir</span>
                    </div>
                    <div style={{textDecoration: 'none' }} className="d-flex flex-column align-items-center link-text"
                        onClick={() => gotoRepair()}>
                      <div className="card-caroussel light-gray-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Repair.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>Réparer</span>
                    </div>
                    <div  className="d-flex flex-column align-items-center link-text"
                        onClick={() => gotoSecondLife()}> 
                      <div className="card-caroussel light-gray-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Secondlife.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>Seconde Vie</span>
                    </div>
                    <div  className="d-flex flex-column align-items-center link-text"
                        onClick={() => gotoSimu()}> 
                      <div className="card-caroussel light-gray-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Secondlife.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>CarboTool</span>
                    </div>
                    <div  className="d-flex flex-column align-items-center link-text"
                        onClick={() => gotoMap()}> 
                      <div className="card-caroussel light-gray-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Secondlife.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>CarboMap</span>
                    </div>
                  </Slider>
                </div>
              

                <div className="mt-3" >
                  <h4 className=" title col-6 d-flex align-items-end fw-bold mb-2" >Mon Portefeuille</h4>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      gap: '10px',
                      marginBottom: '20px',
                      fontSize: '1rem',
                      fontWeight: '500',
                    }}
                  >
                    {/* Rectangle vert */}
                    <div
                      className="green-background"
                      style={{
                        flex: '70%',
                        padding: '15px',
                        borderRadius: '4px',
                        color: 'white',
                        position: 'relative',
                      }}
                    >
                      <p style={{ margin: 0, fontSize: '0.875rem', fontWeight: '400' }}>
                        Impact carbone
                      </p>
                      <p style={{ margin: '0 0 4px 0', fontSize: '1rem', fontWeight: '500' }}>
                        {impactTotal.toFixed(0)} kg CO₂
                      </p>
                      <p style={{ margin: '4px 0 0 0', fontSize: '0.875rem', fontWeight: '400' }}>
                        Nombre d'arbres à planter
                      </p>
                      <p style={{ margin: 0, fontSize: '1rem', fontWeight: '500' }}>
                        {(impactTotal / 35).toFixed(0)} arbres/an
                      </p>
                    </div>

                   {/* Cercle gris avec bord blanc */}
                    <div
                      className="circle-container"
                      style={{
                        width: '140px', // Cercle + stroke (100px + 20px * 2)
                        height: '140px',
                        borderRadius: '50%',
                        backgroundColor: 'white', // Couleur du stroke (blanc)
                        position: 'absolute',
                        right: '-4%',
                        top: '23%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        className='light-gray-background'
                        style={{
                          width: '110px', // Taille réelle du cercle gris
                          height: '110px',
                          borderRadius: '50%',
                          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                        }}
                        >
                          <p style={{ margin: 0, fontSize : '1.5rem', fontWeight : '500'}}>{productTotal}</p>
                          <p style={{ margin: 0, fontSize : '1rem', fontWeight : '400' }}>Appareils</p>
                        </div>
                      </div>
                   
                   
                   
                  </div>

                </div>

                <div>
                    <div className="row d-flex mb-1 mt-4" >
                        <h4 className=" title col-6 d-flex align-items-end fw-bold">
                          Mes Univers</h4>
                          <div 
                              className="col-6 d-flex justify-content-end seemore-link align-items-end"
                              style={{ cursor: 'pointer' }} // Le curseur de la souris reste un pointeur cliquable
                              onClick={gotoUnivers}
                            >
                              {/* Contenu de votre lien ici */}
                              Voir plus &rarr;
                            </div>
                      </div>         
                      {/* Affichage de la liste des univers */}
                      {universToShow && <div className="product-container mt-4 mb-4"> 
                          <div className="d-flex row gx-3">
                              {universToShow.map((univer, index) => (
                                  <div className="col-6 col-md-4 col-lg-3 product-link mb-3" 
                                      key={index}
                                      onClick={() => gotoUniver(univer)}
                                      style={{ cursor: 'pointer' }}>
                                      <div className="text-center"
                                      >
                                          <div className={` mb-1 product-background`}
                                           style={{ backgroundColor: univer.univer_id === -1 ? 'var(--color-light-gray)' : '' }}>
                                              <div className="mb-2" style={{ height: '120px',display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                              <img 
                                                  src={univer.image} 
                                                  alt={univer.name || 'Image descriptive manquante'} 
                                                  className="img-fluid d-block mx-auto"
                                                  style={{ maxHeight:'120px',objectFit: 'cover' }}
                                                  role="img" 
                                              />
                                              </div>
                                              <span className="text-medium" style={{ alignSelf: 'center' }}>{univer.name}</span>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      </div>}
                </div>
              
                <div>
                  {badges && badges.length > 0 && (
                    <div> 
                      <div className="row d-flex mb-1 mt-4" >
                        <h4 className=" title col-6 d-flex align-items-end fw-bold" >Mes Badges</h4>
                        <a 
                          className="col-6 d-flex justify-content-end seemore-link align-items-end"
                          onClick={showAllBadges}// Ajouter l'événement onClick
                          style={{ cursor: 'pointer' }} // Assurez-vous que le curseur indique un lien cliquable
                        >  
                        Voir plus &rarr; {/* Flèche droite simple */}</a>
                      </div>
                      <div className="row d-flex mb-3 mt-3"> 
                          {badges.map((badge) => (
                          <div className="col-3 col-md-2" key={badge.id} >
                              <AppBadge
                                  image={badge.refbadge.pict} 
                              />
                      </div>
                          ))}
                      </div>  
                    </div>
                  )}
                </div>
            </div> 
    );
  }


export default withRouter(Home);