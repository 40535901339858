import React, {useEffect,useState } from "react";
import { FieldText,FieldTextStatic,FieldTextArea } from "../common/EditableField";
import { useNavigate,useLocation } from 'react-router-dom';
import ImageUploader from "../common/ImageUploader";
import UploadServiceInstance from "../../services/upload.service";
import ApplianceServiceInstance from "../../services/appliance.service";
import EventServiceInstance from "../../services/event.service";

//Formulaire pour enregistrer un nouveau device et la panne dessus
const DeviceAndDeviceCheck = ({ product, event,action }) => {
    const location = useLocation();
    const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
    
    console.log('produit recu test',product);
    console.log('event recu',event);


    const [currentDevice, setCurrentDevice] = useState({
      marque: product.marque,
      modele: product.modele,
      prodName: product.product?.prodName|| product?.prodName,
      pict:product?.pict || null,
      filePict:null,
    });

    const [currentPanne, setCurrentPanne] = useState({
      note: event?.note|| ""
    });

   const navigate = useNavigate();


  useEffect(() => {
    
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  // Gestion des changements de champ
  const handlePanneFieldChange = (field, e) => {
    const value = e.target.value;
    if (validateField(field, value)) {
      setCurrentPanne((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  // Gestion des changements de champ
  const SaveEvent = async () => {
   
    try {
      if(currentPanne.note !== event.note){
        event.note = currentPanne.note;
        action(event);
      }
      //alert('Appareil enregistré avec succès !');
  
    } catch (error) {
      console.error(error);
      //alert('Erreur lors de l\'enregistrement de l\'appareil.');
    } finally {
    }
  };
  
   // Fonction de validation pour les champs
   const validateField = (field, value) => {
    switch (field) {
      case 'note':
        return value.length <= 500; // Limite à 500 caractères pour éviter les attaques DoS
      default:
        return true;
    }
  };

  return (
    <div className="mb-4"> 
        <div>
              
        <div className="d-flex mb-3 boxShadow" style={{ marginTop: '15px', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <img
              className="d-block"
              src={currentDevice.pict}
              alt="image"
              style={{ maxWidth: '100%', maxHeight: '120px' }}
            />
          </div>
          <div style={{ flex: 1, textAlign: 'start' }}>
            <FieldTextStatic label="Type de produit" value={currentDevice.prodName} />
            <FieldTextStatic label="Marque" value={currentDevice.marque} />
            <FieldTextStatic label="Modèle" value={currentDevice.modele} />
          </div>
        </div>



              <FieldTextArea
                  label="Description du problème"
                  value={currentPanne.note}
                  onChange={(e) => handlePanneFieldChange("note", e)}
                  required
                />
              
              <button 
                    className="mt-3 btn btn-primary btn-block"
                    onClick={SaveEvent}
                    aria-label="Valider et créer un appareil et une panne">
                    CONFIRMER
                  </button>      
        </div>
    </div>
  );
};

export default DeviceAndDeviceCheck;