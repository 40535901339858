import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";

import { PageTitle } from "../common/Header";

import { FieldText,FieldTextArea } from "../common/EditableField";

// Modal Component
const UpdateConfirmationModal = ({onClose, name}) => {
    return (
      <div style={{
        position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
      }}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '300px', textAlign: 'center' }}>
          <h4>Confirmation</h4>
          <p>Tes modifications sur {name} sont enregistrées</p>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
             <button className="btn btn-primary" onClick={onClose}>Fermer</button>
          </div>
        </div>
      </div>
    );
  };


  // Modal Component
const DeleteConfirmationModal = ({ show, onClose, onConfirm,name }) => {
    if (!show) {
      return null;
    }
  
    return (
      <div style={{
        position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
      }}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '300px', textAlign: 'center' }}>
          <h4>Confirmation</h4>
          <p>Êtes-vous sûr de vouloir supprimer {name}?</p>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems : 'center' }}>
             <button className="btn btn-primary ms-1 me-4" onClick={onClose}>Annuler</button>
            <button className="btn btn-secondary" onClick={onConfirm}>Confirmer</button>
          </div>
        </div>
      </div>
    );
  };
  

const ApplianceUpdateForm = () => {


  const location = useLocation();
  const navigate = useNavigate();

  const { appliance } = location.state || {};

  const [currentAppliance, setCurrentAppliance] = useState(appliance);

  const [isEditable, setIsEditable] = useState({
    marque: false,
    modele: false,
    dt_fab: false,
    note: false,
    dt_achat: false,
  });

  
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control modal visibility
  const [showUpdateModal, setShowUpdateModal] = useState(false); // State to control modal visibility
 
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = () => {
    closeDeleteModal();
    //logout(); // Call logout function on confirmation
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
    navigate(-1);
  };  

  const handleFieldChange = (field, e) =>
    setCurrentAppliance((prevAppliance) => ({ ...prevAppliance, [field]: e.target.value }));

  useEffect(() => {
    console.log('currentappliance ', appliance);
    //setCurrentAppliance(appliance);
  }, [appliance]);


  const updateAppliance= async () => {
    //await AuthService.updateUser(currentUser.id, currentUser.firstname, currentUser.lastname, currentUser.email, currentUser.adresse, currentUser.telephone, currentUser.password);    
    setShowUpdateModal(true);
  };

  return (
    <div className="mt-3">
      <div>
        <PageTitle title={"Modifie les informations"}/>

        <form className="mt-4">
          <FieldText
          label="Marque"
          value={currentAppliance.marque}
          onChange={(value) => handleFieldChange("marque", value)}
          required
          />
         
         <FieldText
          label="Modèle"
          value={currentAppliance.modele}
          onChange={(value) => handleFieldChange("modele", value)}
          required
          />

          <FieldText
          label="Date d'achat"
          value={currentAppliance.dt_achat?currentAppliance.dt_achat:""}
          onChange={(value) => handleFieldChange("dt_achat", value)}
          required
          />

          <FieldText
          label="Date fabrication"
          value={currentAppliance.dt_fab?currentAppliance.dt_fab:""}
          onChange={(value) => handleFieldChange("dt_fab", value)}
          required
          />

          <FieldTextArea
          label="Description"
          value={currentAppliance.note}
          onChange={(value) => handleFieldChange("note", value)}
          required
        />
        </form>

        <button className="mt-4 btn btn-primary btn-block" onClick={updateAppliance}>Confirmer</button>
        <button className="mt-3 mb-4 btn btn-primary btn-block" 
            onClick={openDeleteModal} 
            style={{
                background: '#E4626F',
              }}
            >
            Supprimer
        </button>

        {/* Render Delete Confirmation Modal */}
        {showUpdateModal && <UpdateConfirmationModal show={showUpdateModal} onClose={closeUpdateModal} name={currentAppliance.name}/>}

        {/* Render Delete Confirmation Modal */}
        {showDeleteModal && <DeleteConfirmationModal show={showDeleteModal} onClose={closeDeleteModal} onConfirm={confirmDelete} name={currentAppliance.name}/>}


      </div>
    </div>
  );
};

export default ApplianceUpdateForm;
