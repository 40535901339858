import axios from 'axios';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';
import authHeader from './auth-header';

const API_URL = `${config.serverIP}/${config.apiVersion}/files`;

class UploadService {

    async uploadTempFile(fileToUpload){
        try {
            const url = `${API_URL}/public`;
            const formData = new FormData();
              // Ajouter uniquement les champs modifiés
              if (fileToUpload) {
                formData.append('file', fileToUpload);
              }
        // Récupérer les headers d'authentification
        const headers = authHeader();
        console.log('prepare call ' );
        const response = await axios.post(url, formData, {headers});
        console.log("impage uploaded ",response.data);
        // Gérez la réponse de succès ici
        return response.data;
        } catch (error) {
            console.error(error);
            // Gérez l'erreur ici
        }
    }

    // Fonction pour redimensionner l'image avant l'upload
  resizeImage(file) {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const maxWidth = 800; // Largeur maximale de l'image redimensionnée
          const maxHeight = 800; // Hauteur maximale de l'image redimensionnée

          let width = img.width;
          let height = img.height;

          // Calculer les nouvelles dimensions tout en conservant le ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, { type: file.type });
              resolve(resizedFile);
            } else {
              reject(new Error('Failed to resize image.'));
            }
          }, file.type, 0.8); // Compression à 80% de la qualité
        };
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file); // Lire le fichier image
    });
  }
    
  async checkImageSize(file) {
    const MAX_SIZE = 200 * 1024; // 200 Ko
  
    try {
  
      let fileToUpload = file;
  
      // Si le fichier dépasse la taille limite, redimensionnez-le
      if (file.size > MAX_SIZE) {
        console.log('Redimensionnement de l\'image car elle dépasse 200 Ko...');
        fileToUpload = await this.resizeImage(file); // Assurez-vous que `resizeImage` est dans le bon contexte
      }
  
      return fileToUpload;
    } catch (error) {
      console.error('Erreur lors de la vérification/redimensionnement de l\'image :', error);
      throw error; // Remontez l'erreur pour la gérer en amont
    }
  }
  

  initializeFile = async (filePict,pict,name) => {
    let file;
    // Si pict est présent mais filePict n'est pas initialisé ca veut dire l'image n'a pas été modifié
    if (!filePict && pict) {
      try {
        console.log('calling initialize pict! ');
        const res = await fetch(pict);
        const blob = await res.blob();
        file = new File([blob], `${name}`, { type: blob.type }); // Renommer le fichier avec univerName pour éviter URL préseign
        console.log('file created from pict ********', file);
      } catch (error) {
        console.error('Erreur lors de la création du fichier à partir de pict:', error);
      }
    } else {
      // Si filePict est déjà disponible, utilisez-le directement
      file = new File([filePict], `${name}`, { type: filePict.type }); // Renommer filePict également
    }
    return file;
  }
}


// Créez une instance 
const UploadServiceInstance = new UploadService();

// Exportez l'instance en tant qu'export par défaut
export default UploadServiceInstance;

//export default new UploadService();