import React, { useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";

import { useNavigate,useLocation } from 'react-router-dom'; // Remplace useHistory par useNavigate
import UniverService from '../../services/univer.service'; // Importez votre fonction getUniver si elle est dans un autre fichier

import UniverCRUD from '../common/UniverCRUDForm'
import { PageTitle } from "../common/Header";



const Univers = () => {
    
    const navigate = useNavigate(); // Utilise useNavigate au lieu de useHistory
    const location = useLocation();
    // Définir l'état pour stocker les univers
    const [univers, setUnivers] = useState(location.state?.univers || null);

    const [showCreateModal, setShowCreateModal] = useState(false);

    const [selectedUniver, setSelectedUniver] = useState(null);
 
    const handleClose = (univerCreated) => {
        setShowCreateModal(false);
        if (univerCreated) window.location.reload();
      };
    
      const fetchUnivers = async () => {
        try {
            console.log('récupération univers! ');
            const data = await UniverService.getUniver();  // Récupérer les univers
            const universCustom = data.filter(univer => univer.univer_id !== -1);
            const universReference = data.filter(univer => univer.univer_id === -1);
            const sortedData = [...universCustom, ...universReference]; // Concaténer les deux listes



             setUnivers(sortedData); // Mettre à jour l'état avec les univers triés
            //console.log('univers! ', data);
        } catch (error) {
            console.error("Erreur lors de la récupération des univers:", error);
        }
    };

    // Utiliser useEffect pour récupérer les univers lorsque le composant est monté
    useEffect(() => {
        

        if (univers === null) {
            fetchUnivers();
          }
    }, []);  // Tableau de dépendances vide pour exécuter l'effet au montage uniquement

    
    // Fonction appelée lorsqu'un univers est cliqué
    const gotoUniver = (currentUniver) => {
        if (currentUniver.univer_id !== -1) {
            // Rediriger vers univerdetail et passer l'objet currentUniver dans l'état
            navigate(`/inventaire/univers/${currentUniver.univer_id}/detail`, { state: { univer: currentUniver } });
          } else {
            setSelectedUniver(currentUniver);
            setShowCreateModal(true);
          }
    };
    
    const gotoAddDevice = () => {
         navigate(`/new-device`);
    };

    return (
        
             <div>
                <PageTitle title = {"Mes Univers"}/>     
                
                 {/* Affichage de la liste des univers */}
                {univers && <div className="product-container mt-4 mb-4"> 
                    <div className="d-flex row gx-3">
                        {univers.map((univer, index) => (
                            <div className="col-6 col-md-4 col-lg-3 product-link mb-3" 
                                key={index}
                                onClick={() => gotoUniver(univer)}
                                style={{ cursor: 'pointer' }}>
                                <div className="text-center" style={{ height: "100%" }}
                                >
                                    <div className={` mb-1 product-background`} 
                                        style={{ height: "90%", backgroundColor: univer.univer_id === -1 ? 'var(--color-light-gray)' : ''}}>
                                        <div className="mb-2" style={{ height: '120px',display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                        <img 
                                            src={univer.image} 
                                            alt={univer.name || 'Image descriptive manquante'} 
                                            className="img-fluid d-block mx-auto"
                                            style={{ maxHeight:'120px',objectFit: 'cover' }}
                                            role="img" 
                                        />
                                            
                                        </div>
                                        <span className="text-medium" style={{ alignSelf: 'center' }}>{univer.name}</span>
                                    </div>

                                    <div className="light-gray-background text-small" style={{ borderRadius: "4px"}}>
                                    {univer.nbAppliance === 0 
                                        ? 'Vide' 
                                        : univer.nbAppliance === 1 
                                            ? '1 appareil' 
                                            : `${univer.nbAppliance} appareils`}
                                    </div>
                                </div>
                                
                                
                            </div>
                        ))}
                    </div>
                </div>}


                {/* Render Logout Confirmation Modal */}
                {showCreateModal && <UniverCRUD show={showCreateModal} onClose={handleClose} univer={selectedUniver} action='CREATE' isModal={true}/>}
                            

                <button className="mb-5 btn btn-primary btn-block"
                onClick={gotoAddDevice}>
                  <span>AJOUTER UN APPAREIL</span>
                </button>

                

            </div>
    );
};

export default Univers;