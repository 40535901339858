import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation,useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap"; 
import { PageTitle } from "../common/Header"; 


import ReferencesServiceInstance from "../../services/references.service"; 

import ApplianceService from "../../services/appliance.service";
import AuthService from "../../services/auth.service";

import SwitchButton from "../common/SwitchButton";

import ApplianceListView from "../common/ApplianceListView";


const ProductList = () => {
  
  const [refProductList, setRefProductList] = useState([]);
  const [userProductList, setUserProductList] = useState([]);

  // État pour stocker le terme de recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [voirPlus, setVoirPlus] = useState(false);
  const [currentList, setCurrentList] = useState('Produits génériques');



  const location = useLocation();
  const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;

  const { toadd, tosecond, tomaintenance, torepair } = state || {};



  const navigate = useNavigate();
  

  const handleClicktoDetail = (product) => {
    // Pour le moment on bride sur prodName: "Lave-Linge"
    const selectedProduct = refProductList.find(product => product.prodName === "Lave-Linge");

    const updateproduct = {
      prod_id: selectedProduct.id,
      prod_name: selectedProduct.prodName
    };

    console.log("product choisi", product);
    //redirection vers la page d'ajout du produit
    if (toadd) navigate(`/inventaire/nouveau_2`, { state: { product } });
    else if (tosecond) navigate(`/secondlife/actionselect`, { state: { product: updateproduct } });
    else if (torepair) navigate(`/repair`, { state: { product: product } });
    //redirection vers la page des fiches de maintenance
    else navigate(`/entretien/fiche`, { state: { product: updateproduct } });
  };

  const handleSwitchChange = (selectedList) => {
    console.log("change list", selectedList);
    setCurrentList(selectedList);
  };
  
  
  useEffect(() => {
    
    const fetchProductList = async () => {
      try {
          const refProductList = await ReferencesServiceInstance.getAllProduct();  // Récupérer les refproduct
          if (Array.isArray(refProductList)) {
            setRefProductList(refProductList);  // Mettre à jour l'état avec les refproductlist
          } else {
            console.error("Les données renvoyées ne sont pas un tableau :", refProductList);
          }
          const user = AuthService.getCurrentUser();
          const userProductList = await ApplianceService.getApplianceByUser(user.id);
          console.log("userProduct ", userProductList)
          setUserProductList(userProductList.data);
      } catch (error) {
          console.error("Erreur lors de la récupération des listes de produit:", error);
      }
    };

    fetchProductList();  // Appel de la fonction asynchrone pour récupérer les univers

  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial


  // Adapter la structure de userProductList pour qu'elle corresponde à refProductList
  const adaptedUserProductList = Array.isArray(userProductList)
  ? userProductList.map(product => ({
      ...product,
      prodName: product.prod_name, // Adapter le nom de la propriété
    }))
  : []; // Retourner un tableau vide si userProductList n'est pas un tableau

  console.log("adapted userproductlist", adaptedUserProductList);


    // Déterminer quelle liste afficher en fonction du switch
  const listToDisplay = currentList === "Produits génériques" ? refProductList : adaptedUserProductList;

  
  return (
          <div>
            <div className="justify-content-center mb-4"> 
              {/* Titres dynamiques pour le lecteur d'écran */}
              {tomaintenance && (
                <h2 id="page-title-maintenance">
                  <PageTitle title="Choisir le produit à entretenir" />
                </h2>
              )}
              {toadd && (
                <h2 id="page-title-add">
                  <PageTitle title="Choisir le produit à ajouter" />
                </h2>
              )}
              {tosecond && (
                <h2 id="page-title-second">
                  <PageTitle title="Choisir le produit pour lui donner une seconde vie" />
                </h2>
              )}
              {torepair && (
                <h2 id="page-title-second">
                  <PageTitle title="Choisir le produit à réparer" />
                </h2>
              )}

              {/* SwitchButton avec des attributs pour l'accessibilité */}
              <div role="group" aria-labelledby="switch-label">
                <p id="switch-label" className="visually-hidden">
                  Sélectionnez entre Produits génériques et Produits enregistrés
                </p>
                <SwitchButton
                  leftLabel="Produits génériques"
                  rightLabel="Produits enregistrés"
                  onChange={handleSwitchChange}
                  aria-label="Choisir entre Produits génériques et Produits enregistrés"
                />
              </div>

              {/* Liste des produits */}
              <ApplianceListView 
                listToDisplay={listToDisplay} 
                handleClicktoDetail={handleClicktoDetail} 
                aria-label="Liste des produits disponibles"
              />
            </div>
          </div>

  );
};

export default ProductList;

