import React from 'react';

const DonutChart = ({ labels, data, backgroundColors, cutout = '88%' }) => {
  const chartType = 'doughnut';
  const quickChartBaseUrl = "https://quickchart.io/chart?v=3&c=";

  // Configuration du graphique
  const chartConfig = {
    type: chartType,
    data: {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: backgroundColors,
          borderRadius: 20,
          borderWidth: 0,
          borderColor: '#FFFFFF',
          borderJoinStyle: 'round',
          spacing: 10,
        },
      ],
    },
    options: {
      cutout: cutout,
      rotation: -90, // Début de l'arc (en degrés, -90 commence au haut)
      circumference: 180, // Afficher seulement 180° (moitié de cercle)
      plugins: {
        datalabels: {
            display: false,
        },
        legend: {
          display: false,
        },
      },
      elements: {
        arc: {
          borderRadius: 20,
        },
      },
    },
  };

  // Générer l'URL pour le graphique
  const chartUrl = quickChartBaseUrl + encodeURIComponent(JSON.stringify(chartConfig));
  const total = data.reduce((a, b) => a + b, 0);


  return (
    <div className="chart-container">
    <div className="chart-text">
        <div className="label">CO₂</div>
        <div className="value">{total.toFixed(0)} kg</div>
        
    </div>
    <img
        className="chart-image"
        alt=""
        src={chartUrl}
    />
</div>

  );
};

export default DonutChart;
