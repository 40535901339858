import React, { useState, useRef, useEffect } from "react";

const CustomSlider = ({ min = 1, max = 20, defaultValue = 1, onChange }) => {
  const [value, setValue] = useState(defaultValue);
  const sliderRef = useRef(null);


    const increment = () => {
      if (value < max) {
        const newValue = value + 1;
        setValue(newValue);
        if (onChange) onChange(newValue);
      }
    };

    const decrement = () => {
      if (value > min) {
        const newValue = value - 1;
        setValue(newValue);
        if (onChange) onChange(newValue);
      }
    };


  // Synchroniser l'état interne lorsque `defaultValue` change
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleMouseMove = (e) => {
    if (!sliderRef.current) return;

    const rect = sliderRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left; // Position relative à la barre
    const sliderWidth = rect.width;

    // Limiter le curseur à l'intérieur des bornes
    const percentage = Math.max(0, Math.min(offsetX / sliderWidth, 1));

    // Convertir la position en une valeur
    const newValue = Math.round(min + percentage * (max - min));
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleMouseDown = () => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
  style={{
    display: "flex", // Disposition en ligne
    alignItems: "center", // Aligne les éléments verticalement au centre
    justifyContent: "space-between", // Gère l'espacement entre les éléments
    width: "100%",
     gap: "5px", // Espace entre les boutons et le slider
  }}
>
  {/* Bouton pour décrémenter */}
  <button
    onClick={decrement}
    className="light-green-background text-medium"
    style={{
      padding: "5px",
      width: "20px", // Largeur du bouton
      height: "20px", // Hauteur du bouton
      color: "var(--color-green)",
      border: "none",
      borderRadius: "50%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    -
  </button>

  {/* Slider */}
  <div
    ref={sliderRef}
    className="light-green-background"
    style={{
      position: "relative",
      flex: "1", // Permet au slider de prendre toute la largeur disponible
      height: "10px",
      borderRadius: "5px",
      border: "none",
    }}
    onMouseDown={handleMouseMove}
  >
    {/* Partie sélectionnée */}
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: `${((value - min) / (max - min)) * 100}%`,
        background: "var(--color-green)",
        borderRadius: "5px 0 0 5px",
      }}
    ></div>

    {/* Curseur avec valeur */}
    <div
      onMouseDown={handleMouseDown}
      style={{
        position: "absolute",
        top: "-5px",
        left: `${((value - min) / (max - min)) * 100}%`,
        transform: "translateX(-50%)",
        width: "18px",
        height: "18px",
        background: "var(--color-green)",
        borderRadius: "50%",
        cursor: "pointer",
      }}
    >
      {/* Texte de la valeur */}
      <div
        style={{
          position: "absolute",
          top: "-25px", // Position au-dessus du curseur
          left: "50%",
          transform: "translateX(-50%)",
          width:"50px",
          background: "white",
          color: "black",
          padding: "2px 5px",
          borderRadius: "3px",
          boxShadow: "0 0 5px rgba(0,0,0,0.1)",
        }}
      >
        {value} ans
      </div>
    </div>
  </div>

  {/* Bouton pour incrémenter */}
  <button
    onClick={increment}
    className="light-green-background text-medium"
    style={{
      padding: "2px",
      width: "20px", // Largeur du bouton
      height: "20px", // Hauteur du bouton
      color: "var(--color-green)",
      border: "none",
      borderRadius: "50%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    +
  </button>
</div>


  );
};

export default CustomSlider;
