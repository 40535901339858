import React from 'react';

const ColChart = ({ labels, data, backgroundColors }) => {
  const chartType = 'bar';
  const quickChartBaseUrl = "https://quickchart.io/chart?v=3&c=";

  // Configuration du graphique
  const chartConfig = {
    type: chartType,
    data: {
      labels: labels,
      datasets: [
        {
          label: 'kg CO₂',
          data: data,
          backgroundColor: backgroundColors,
          borderColor: '',
          borderRadius: 10,
          barThickness: 30, // Contrôle la largeur des barres
        },
      ],
    },
    options: {
      indexAxis: 'y', // Orientation des barres (x pour horizontal, y pour vertical)
      scales: {
        x: {
            
            display: false, // Cache l'échelle x
            grid: {
            display: false, // Masquer la grille sur l'axe x
            },
            ticks: {
                font: {
                size: 16,
                },
            },
            min: 0, // Début de l'axe X
            max: Math.max(...data) * 1.3, // Ajuste la longueur maximale des barres (ajout de 20 % pour espacer un peu)
        },
        y: {
            display: true, // Cache l'échelle y
          grid: {
            drawBorder: false,
            display: false, 
          },
          ticks: {
            font: {
              size: 18,
              weight: 'bold', // Met en gras les labels
            },
          },
        },
      },
      plugins: {
        datalabels: {
            display: true, // Affiche les valeurs des données
            color: '#000', // Couleur des valeurs
            font: {
              size: 20, // Taille de police des valeurs
              weight: 'light', // Épaisseur de la police
            },
            anchor: 'end', // Position par rapport à la barre
            align: 'right', // Aligne au sommet des barres
          },
        legend: {
          display: false,
        },
      },
    },
  };

  // Générer l'URL pour le graphique
  const chartUrl = quickChartBaseUrl + encodeURIComponent(JSON.stringify(chartConfig));
  const total = data.reduce((a, b) => a + b, 0);

  return (
    <div className="chart-container">
      <img
        className="chart-image"
        style={{ height:'auto' }}
        alt="ColChart"
        src={chartUrl}
      />
    </div>
  );
};

export default ColChart;
