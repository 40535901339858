import React, { useState, memo} from 'react';
import CameraCapture from './CameraCapture'; // Assurez-vous que le chemin d'importation est correct

import {Button } from "react-bootstrap"; 
import { FileIcon } from './Icon';


const ImageUploader = ({onImageUpload }) => {
  
  const [selectedImage, setSelectedImage] = useState(null);
  const [urlSelectedImage, setURLSelectedImage] = useState(null);
  const [useCamera, setUseCamera] = useState(false);
  const fileInputRef = React.createRef(); 


  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file)
      setSelectedImage(file); // Met à jour l'image sélectionnée
      setURLSelectedImage(url);
      onImageUpload(url, file); // Appeler onImageUpload après la mise à jour
    }
  };

  const handleUseCamera = (e) => {
    setUseCamera(true)
    setSelectedImage(null);
    setURLSelectedImage(null);
    onImageUpload(null,null);
  };

  const handleCapture = (blob) => {
    const imgFile = new File([blob], "captured_image.jpg", { type: "image/jpeg", lastModified: new Date() });
    setSelectedImage(imgFile);
    console.log("image captured ", imgFile);
    const url = URL.createObjectURL(imgFile)
    onImageUpload(url,imgFile ); // Appeler onImageUpload après la mise à jour
    setUseCamera(false); // Cachez la caméra après la capture
  };

  const closeCapture = () => {
    setUseCamera(false)
  };


  return (
    <div className="mt-3 mb-3 text-small">
      {useCamera ? (
        <CameraCapture
          onCapture={handleCapture}
          onClose={closeCapture}
          aria-label="Caméra activée pour capturer une image"
        />
      ) : (
        <form aria-label="Formulaire de téléchargement de fichier">
          <div>
            {urlSelectedImage && (
              <div
                className="mb-3"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                aria-live="polite"
                aria-describedby="selected-file-description"
              >
                {selectedImage?.type.startsWith('image/') ? (
                  <img
                    src={urlSelectedImage}
                    alt="Prévisualisation de l'image sélectionnée"
                    style={{
                      width: 'auto',
                      height: '120px',
                      margin: '0 auto',
                    }}
                  />
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <span
                      style={{ fontSize: '2rem', display: 'block' }}
                      role="img"
                      aria-label="Icône de fichier non image"
                    >
                      <FileIcon/>
                    </span>
                    <span id="selected-file-description">
                      {selectedImage?.name}
                    </span>
                  </div>
                )}
              </div>
            )}
  
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
              accept="image/*,application/pdf"
              style={{ display: 'none' }}
              id="file-upload"
              aria-labelledby="file-upload-label"
            />
            <div
              className="mt-3 mb-2"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Button
                className="col btn btn-secondary btn-block me-2"
                onClick={() => fileInputRef.current.click()}
                aria-labelledby="file-upload-label"
              >
                Fichier
              </Button>
              <Button
                className="col btn btn-secondary btn-block"
                onClick={handleUseCamera}
                aria-label="Activer la caméra pour capturer une image"
              >
                Caméra
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
  
}

export default ImageUploader;
