import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Stepper from '../common/Stepper';
import PanneAndDeviceCreate from './PanneAndDeviceCreate'
import PanneAndDeviceCheck from './PanneAndDeviceCheck'

const RepairPage = () => {

  const navigate = useNavigate(); // Utilise useNavigate au lieu de useHistory
    

    // Récupérer l'objet univer depuis l'état de location
    const location = useLocation();
    // Récupérer les objets product et event de location.state
    const {product, event } = location.state || {};
    const [currentPanne, setCurrentPanne] = useState("");
    const [currentDevice, setCurrentDevice] = useState("");


    const [currentStep, setCurrentStep] = useState(0);
    const [transitionClass, setTransitionClass] = useState('');

    // Vérifier si les objets sont présents
    console.log("Product:", product);
    console.log("Repair Event:", event);
  
    const steps = ['Description','Troubleshooting', 'Solution'];

    

      useEffect(() => {
        
        const fetchProductList = async () => {
            try {
              
            } catch (error) {
              console.error("Erreur lors de la récupération des listes de produit:", error);
          }
        };
        
        //if (!refProductList) {
        //  fetchProductList();  // Appel de la fonction asynchrone pour récupérer les univers
        //}
      }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial
  
      const getNewPanne = (device, eventPanne) => {
        setCurrentPanne(eventPanne);
        setCurrentDevice(device);
        nextStep();
  
      };
  
      const nextStep = () => {
        setTransitionClass('slide-left'); // Ajoute la classe de transition
        setTimeout(() => {
          setCurrentStep((prev) => Math.min(prev + 1, steps.length));
          setTransitionClass(''); // Retire la classe de transition après le changement de page
        }, 500); // Durée de l'animation en ms (doit correspondre avec le CSS)
      };
      
      const prevStep = () => {
        if (currentStep === 0) navigate(-1);
        else setCurrentStep((prev) => Math.max(prev - 1, 0));
      };

      
  return (
    <div>
     
      <div className='mt-4 mb-4'>
        <Stepper steps={steps} currentStep={currentStep} />
      </div>

      <div className="mb-4" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div onClick={prevStep} className="button-back">&lt;</div>
        <div className="title-container">Réparation</div>
      </div>
      
      <div className={`step-container ${transitionClass}`}>
      {currentStep===0 && !product.name && <PanneAndDeviceCreate action={getNewPanne}/>}
      {currentStep===0 && product.name && <PanneAndDeviceCheck product={product} event={event} action={getNewPanne}/>}

      </div>
    </div>
  );
};

export default RepairPage;