import React, { useEffect, useRef } from 'react';

const ADEMEActorMap = ({ typeaction }) => {
  const actionMapping = {
    all: [
      'preter',
      'emprunter',
      'louer',
      'mettreenlocation',
      'reparer',
      'donner',
      'echanger',
      'acheter',
      'revendre',
    ],
    location: ['preter', 'emprunter', 'louer', 'mettreenlocation'],
    reparation: ['reparer'],
    secondevie: ['donner', 'echanger','revendre'],
    renew: ['acheter'],
  };

  const actions = actionMapping[typeaction] || actionMapping.all;
  const limit = 300;
  const boundingBox = {
    southWest: { lat: 42.962014, lng: -6.895088 },
    northEast: { lat: 51.101714, lng: 7.649861 },
  };
  const containerRef = useRef(null);

  useEffect(() => {
    const iframe = document.createElement('iframe');
    const boundingBoxEncoded = encodeURIComponent(
      JSON.stringify(boundingBox)
    );
    const actionsEncoded = encodeURIComponent(actions.join('|'));

    // URL de l'iframe
    iframe.src = `https://lvao.ademe.fr?carte=1&action_list=preter%7Cemprunter%7Clouer%7Cmettreenlocation%7Creparer%7Cdonner%7Cechanger%7Cacheter%7Crevendre&bounding_box=%7B%22southWest%22%3A%7B%22lat%22%3A47.457526%2C%22lng%22%3A-0.609453%7D%2C%22northEast%22%3A%7B%22lat%22%3A47.489048%2C%22lng%22%3A-0.51571%7D%`;
    //`https://lvao.ademe.fr?carte=1&action_list=${actionsEncoded}&action_displayed=${actionsEncoded}&limit=${limit}&bounding_box=${boundingBoxEncoded}&sous_categorie_objet=Lave-linge&bonus=on`;
    
    //https://lvao.ademe.fr?carte=1&action_list=preter%7Cemprunter%7Clouer%7Cmettreenlocation%7Creparer%7Cdonner%7Cechanger%7Cacheter%7Crevendre&bounding_box=%7B%22southWest%22%3A%7B%22lat%22%3A47.457526%2C%22lng%22%3A-0.609453%7D%2C%22northEast%22%3A%7B%22lat%22%3A47.489048%2C%22lng%22%3A-0.51571%7D%
    iframe.id = 'lvao_iframe';
    iframe.style.cssText =
      'width: 100%; height: 100%; border: none; overflow: hidden;';
    iframe.allow = 'geolocation; clipboard-write';
    iframe.allowFullscreen = true;
    iframe.title = 'Longue vie aux objets';

    // Nettoyage et ajout de l'iframe
    const container = containerRef.current;
    if (container) {
      if (!container.firstChild) {
        container.appendChild(iframe); // Charge seulement si l'iframe n'existe pas
      } else if (container.firstChild.src !== iframe.src) {
        container.replaceChild(iframe, container.firstChild); // Met à jour seulement si l'URL change
      }
    }
  }, [actions, boundingBox, limit]);

  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '600px',
        border: '1px solid #ccc',
        position: 'relative',
      }}
    />
  );
};

export default ADEMEActorMap;
