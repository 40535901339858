import React, {useEffect,useState } from "react";
import { FieldText,FieldTextArea } from "../common/EditableField";
import { useNavigate,useLocation } from 'react-router-dom';
import ImageUploader from "../common/ImageUploader";
import UploadServiceInstance from "../../services/upload.service";
import ApplianceServiceInstance from "../../services/appliance.service";

//Formulaire pour enregistrer un nouveau device et la panne dessus
const PanneAndDeviceCreate = () => {
    const location = useLocation();
    const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
    const { product } = state|| {}; // product générique
    
    const [currentDevice, setCurrentDevice] = useState({
      marque: '',
      modele: '',
      pict:product?.pict || null,
      filePict:null,
    });

    const [currentPanne, setCurrentPanne] = useState({
      note: ''
    });

   const navigate = useNavigate();
  

  // Utilisez useCallback pour mémoriser handleImageUpload et éviter qu'il soit recréé à chaque re-render
  const handleImageUpload = (uploadedImage, fileupload) => {
    console.log('uploaded image jjjjjjjjjjpopdsdqsdkq');
    handleDeviceFieldChange("pict",uploadedImage);  // Mettez à jour url de l'image téléchargée
    handleDeviceFieldChange("filePict",fileupload);//Mettez à jour le fichier
    console.log('file updated ********', currentDevice.filePict)
  };  


  useEffect(() => {
    console.log('produit recu',product);
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  const handleClicktoGive = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/secondlife/give`,{ state: { product } });
  };

  // Gestion des changements de champ
  const handleDeviceFieldChange = (field, e) => {
    const value = e.target.value;
    if (validateField(field, value)) {
      setCurrentDevice((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  // Gestion des changements de champ
  const handlePanneFieldChange = (field, e) => {
    const value = e.target.value;
    if (validateField(field, value)) {
      setCurrentPanne((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  // Gestion des changements de champ
  const createDeviceAndPanne = async () => {
    console.log("device simple",currentDevice);
    
    const name = currentDevice.marque+" "+currentDevice.modele;
   
    const filetouse=await UploadServiceInstance.initializeFile(currentDevice.filePict,currentDevice.pict,name);
    

    console.log("image to use",filetouse);
    const data = {
      dt_achat: null,
      prod_id: product.id,
      name:name,
      marque:currentDevice.marque,
      modele:currentDevice.modele,
      facture: null,
      notice: null,
      note: null,
      filepict: filetouse,
      panne_note: currentPanne.note,
    };

    try {

      const result = await ApplianceServiceInstance.saveAppliance("default",data);
      //alert('Appareil enregistré avec succès !');
      console.log(result);
    } catch (error) {
      console.error(error);
      //alert('Erreur lors de l\'enregistrement de l\'appareil.');
    } finally {
    }
  };
  
   // Fonction de validation pour les champs
   const validateField = (field, value) => {
    switch (field) {
      case 'marque':
      case 'modele':
      case 'note':
        return value.length <= 500; // Limite à 500 caractères pour éviter les attaques DoS
      default:
        return true;
    }
  };

  return (
    <div className="mb-4 boxShadow"> 
        <div>
              <span className="text-medium">
                Type de produit : <span className="text-small">{product.prodName}</span>
              </span>

              <div style={{ marginTop: '15px',textAlign: 'start'}}>
                  {!currentDevice.filepict ? (
                    <div>
                      <div className="mb-2 text-medium" >Image actuelle</div>
                      <img className= "d-block mx-auto" src={currentDevice.pict}  
                      alt="image" style={{ maxWidth: '100%', maxHeight:'120px'}} />
                      <div className="mt-2 text-medium">Remplacer avec ma photo</div>
                    </div>
                  ) : (
                    <div>
                      <div className="mb-2 text-medium">Ma photo de {product.prodName}</div>
                    </div>
                  )}
                  <ImageUploader onImageUpload={handleImageUpload}/>
                </div>



               {/* Formulaire pour le nom de l'univers */}
                <FieldText
                  label="Marque"
                  value={currentDevice.marque}
                  onChange={(e) => handleDeviceFieldChange("marque", e)}
                  required
                />
              {/* Formulaire pour le nom de l'univers */}
              <FieldText
                  label="Modele"
                  value={currentDevice.modele}
                  onChange={(e) => handleDeviceFieldChange("modele", e)}
                  required
                />

              <FieldTextArea
                  label="Description du problème"
                  value={currentDevice.note}
                  onChange={(e) => handlePanneFieldChange("note", e)}
                  required
                />
              
              <button 
                    className="mt-3 btn btn-primary btn-block"
                    onClick={createDeviceAndPanne}
                    aria-label="Valider et créer un appareil et une panne">
                    VALIDER
                  </button>      
        </div>
    </div>
  );
};

export default PanneAndDeviceCreate;