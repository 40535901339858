import axios from 'axios';
import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';
import UploadServiceInstance from './upload.service';

const API_URL = `${config.serverIP}/${config.apiVersion}/profils/users`;

class ApplianceService {

  getApplianceByUser(userId) {
    console.log('ID du user à récupérer :', userId);
     // Construire l'URL avec l'ID utilisateur et univerid
    const url = `${API_URL}/${userId}/appliances`;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  getApplianceCountByUser = async (userId) => {
    try {
      if (!userId) {
        throw new Error('User ID est requis pour effectuer cette requête.');
      }
  
      const url = `${API_URL}/${userId}/appliances/count`;
      const response = await axios.get(url, { headers: authHeader() });
      return response.data; // Retourner directement les données pour le composant
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error);
      // Relancer l'erreur pour permettre sa gestion dans le composant
      throw error;
    }
  };
  



  getApplianceByUserAndUniver(userId, univerId) {
    console.log('ID du user à récupérer :', userId);
     // Construire l'URL avec l'ID utilisateur et univerid
    const url = `${API_URL}/${userId}/foyers/univers/${univerId}/appliances`;
    //const url=API_URL+'/list?userid='+userid+'&univerid='+univerid;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  getApplianceDetail(applianceId) {
    console.log('ID appareil à récupérer :',applianceId);
     // Construire l'URL avec l'ID utilisateur et univerid
    const url = `${API_URL}/foyers/univers/appliances/${applianceId}`;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  updateApplianceText = async (userId, appliance) => {
    try {
      const url = `${API_URL}/${userId}/foyers/univers/appliances/${appliance.id}/text`;
      // Récupérer les headers d'authentification
      const headers = authHeader();
      const response = await axios.put(url, appliance,{headers});
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la mise à jour :', error);
      throw error; // relance l'erreur pour la gestion dans le composant si nécessaire
    }
  };

  updateApplianceFacture = async (userId, applianceId, facture) => {
    try {
      const url = `${API_URL}/${userId}/foyers/univers/appliances/${applianceId}/facture`;

      let fileToUpload = UploadServiceInstance.checkImageSize(facture);

      const formData = new FormData();
      formData.append("file", fileToUpload);
      // Récupérer les headers d'authentification
      const headers = authHeader();
      const response = await axios.put(url, formData, {headers});

      return response.data;
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la facture :', error);
      throw error; // relance l'erreur pour la gestion dans le composant si nécessaire
    }
  };

  updateAppliancePict = async (userId, applianceId, file) => {
    const url = `${API_URL}/${userId}/foyers/univers/appliances/${applianceId}/image`;
    console.log('url calling ', url);

    let fileToUpload = UploadServiceInstance.checkImageSize(file);

    const formData = new FormData();
    formData.append("file", fileToUpload);
    // Récupérer les headers d'authentification
    const headers = authHeader();
    try {
      const response = await axios.put(url, formData, {headers});
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image :", error);
      throw error;
    }
  };

  updateApplianceInvoice = async (userId, applianceId, file) => {
    const url = `${API_URL}/${userId}/foyers/univers/appliances/${applianceId}/facture`;
    console.log('url calling ', url);
    let fileToUpload = UploadServiceInstance.checkImageSize(file);

    const formData = new FormData();
    formData.append("file", fileToUpload);
    // Récupérer les headers d'authentification
    const headers = authHeader();
    try {
      const response = await axios.put(url, formData, {headers});
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image :", error);
      throw error;
    }
  }

  saveAppliance = async (univerId, applianceData) => {
    try {
      // Récupérer l'utilisateur et son ID depuis localStorage
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user?.id;

      console.log("appliance data ", applianceData)
  
      // Générer `dt_creation` avec la date actuelle
      const dt_creation = new Date().toISOString(); // Format ISO 8601
  
      // Construire l'objet `data` avec les champs obligatoires et optionnels
      const data = {
        dt_creation: dt_creation,
        prod_id: applianceData.prod_id,
        name: applianceData.name,
        marque:applianceData.marque,
        modele: applianceData.modele,
        dt_achat: applianceData.dt_achat || null,
        facture: applianceData.facture || null,
        notice: applianceData.notice || null,
        note: applianceData.note || null,
        panne_note: applianceData.panne_note || null,
      };
  
      // Créer FormData pour inclure `data` et les fichiers
      const formData = new FormData();
      formData.append('data', JSON.stringify(data)); // Envoyer `data` en tant que JSON string
  
      // Ajouter l'image si présente
      if (applianceData.filepict) {
        formData.append('pict', applianceData.filepict); // Image en tant que fichier
      }

      // Récupérer les headers d'authentification
      const headers = {
        ...authHeader(),
        'Content-Type': 'multipart/form-data', // Assurez-vous d'avoir ce header
      };
  
      const url = `${API_URL}/${userId}/foyers/univers/${univerId}/appliances`;
  
      // Appel API
      const response = await axios.post(url, formData, { headers });
  
      console.log('Appliance saved successfully:', response.data);
      return response.data; // Retourner les données si nécessaire
    } catch (error) {
      console.error('Error saving appliance:', error);
      throw error; // Remonter l'erreur si besoin
    }
  };  

}



// Créez une instance de NotifService
const ApplianceServiceInstance = new ApplianceService();

// Exportez l'instance en tant qu'export par défaut
export default ApplianceServiceInstance;

//export default new ApplianceService();