import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import UniverSelect from './UniverSelect';
import { ArmIcon, PlanetIcon } from '../common/Icon';

import Stepper from '../common/Stepper';

import ReferencesServiceInstance from '../../services/references.service';
import NewDeviceDetection from './NewDeviceDetection';
import NewDeviceConfirm from './NewDeviceConfirm';

const AddDevicePage = () => {

  const navigate = useNavigate(); // Utilise useNavigate au lieu de useHistory
    

    // Récupérer l'objet univer depuis l'état de location
    const location = useLocation();
    const { univer } = location.state || {}; // L'objet univer est dans location.state

    const [currentStep, setCurrentStep] = useState(univer? 1:0);
    const [univerChoice, setUniverChoice] = useState(univer? univer:null);

    const [refProductList, setRefProductList] = useState(null);

    const [newDevice, setNewDevice] = useState("");
    const [transitionClass, setTransitionClass] = useState('');

    
  
    const steps = ['Univer','Détails', 'Confirmation','Terminé'];

    const getNewDevice = (device) => {
      setNewDevice(device);
      console.log('in get newdevice')
      nextStep();

    };

      useEffect(() => {
        
        const fetchProductList = async () => {
            try {
              const refProductList = await ReferencesServiceInstance.getAllProduct();  // Récupérer les refproduct
                if (Array.isArray(refProductList)) {
                  console.log ('get productlist', refProductList)
                    setRefProductList(refProductList);  // Mettre à jour l'état avec les refproductlist
                } else {
                    console.error("Les données renvoyées ne sont pas un tableau :", refProductList);
                }
            } catch (error) {
              console.error("Erreur lors de la récupération des listes de produit:", error);
          }
        };
        
        if (!refProductList) {
          fetchProductList();  // Appel de la fonction asynchrone pour récupérer les univers
        }
      }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial
  

      const nextStep = () => {
        setTransitionClass('slide-left'); // Ajoute la classe de transition
        setTimeout(() => {
          setCurrentStep((prev) => Math.min(prev + 1, steps.length));
          setTransitionClass(''); // Retire la classe de transition après le changement de page
        }, 500); // Durée de l'animation en ms (doit correspondre avec le CSS)
      };
      
      const prevStep = () => {
        if (currentStep === 0) navigate(-1);
        else setCurrentStep((prev) => Math.max(prev - 1, 0));
      };

      const gotoInventaire = () => {
        navigate(`/inventaire/univers`);
      };
      
  return (
    <div>
     
      <div className='mt-4 mb-4'>
        <Stepper steps={steps} currentStep={currentStep} />
      </div>
      <br/>
      <div className="mb-4" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div onClick={prevStep} className="button-back">&lt;</div>
        <div className="title-container">Nouveau Produit</div>
      </div>
      
      <div className={`step-container ${transitionClass} boxShadow`}>
        {currentStep===0 && <UniverSelect univer={univer} nextstep={nextStep} setUniverChoice={setUniverChoice}/>}

        {currentStep===1 && univerChoice && (<NewDeviceDetection
            refProductList={refProductList}
            univerChoice={univerChoice}
            getAppliance={getNewDevice}
          />)}

        {currentStep===2 && (
           <NewDeviceConfirm newDevice={newDevice} getFinalDevice={getNewDevice}/>
    
        )}
        {currentStep===3 && (
           <div>
              <h1 className="text-center">Bravo ! Tu as fait un geste pour la planète !</h1>
              <div align="center" className="mt-2 mb-2">
                <PlanetIcon/>
              </div>
              <p style={{fontSize: '1rem', height: 'auto' }}>Tu pourras prolonger sa durée de vie et profiter de tous nos services pour en prendre soin. Ensemble, on participe à l'économie circulaire ! <ArmIcon/></p>
              <button onClick={gotoInventaire} className="mt-3 btn btn-primary btn-block" type="submit">
                  Retour
              </button>
          </div>
    
        )}


       
      </div>
    </div>
  );
};

export default AddDevicePage;